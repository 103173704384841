<template>
  <div class="container">
    <div class="container_main">
      <div class="container_main_left">
        <div class="container_main_left_title">Account</div>
        <div
          :class="[infoActive===true ?'container_main_left_tabtitle--choosed' : 'container_main_left_tabtitle']"
          @click="changeTab(1)"
        >Userinfomation</div>
        <div
          :class="[passActive===true ?'container_main_left_tabtitle--choosed' : 'container_main_left_tabtitle']"
          @click="changeTab(2)"
        >Password</div>
        <div
          :class="[logActive===true ?'container_main_left_tabtitle--choosed' : 'container_main_left_tabtitle']"
          @click="changeTab(3)"
        >Log Out</div>
      </div>
      <div class="container_main_right">
        <div v-show="infoActive">
          <div class="container_main_right_title">
            User information
          </div>
          <div class="container_main_right_company">
            <div class="container_main_right_company_left">
              <div class="container_main_right_company_left_img"><img
                  src="../../assets/images/home/logo.png"
                  alt=""
                ></div>
              <div class="container_main_right_company_left_title">ABC Company</div>
            </div>
            <div class="container_main_right_company_right">
              <div class="container_main_right_company_right_btn">
                update avatar
              </div>
            </div>
          </div>

          <div class="container_main_right_form">
            <div class="container_main_right_form_title">
              Basic Information
            </div>
            <div class="container_main_right_form_body">
              <el-form
                ref="forms"
                :model="forms"
                @submit="submitForm"
              >
                <el-form-item
                  label="Company Name"
                  prop="companyName"
                >
                  <el-input
                    v-model="forms.companyName"
                    placeholder="Enter Company Name"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="CompanyAddress"
                  prop="companyAddress"
                >
                  <el-input
                    v-model="forms.companyAddress"
                    placeholder="Enter Company Address"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="Company switchboard"
                  prop="switchboard"
                >
                  <el-input
                    v-model="forms.switchboard"
                    placeholder="Enter Company switchboard"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="Contacts"
                  prop="Contacts"
                >
                  <el-input
                    v-model="forms.Contacts"
                    placeholder="Enter Contacts"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="Contact Email"
                  prop="email"
                >
                  <el-input
                    v-model="forms.email"
                    placeholder="Enter Company Email"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="Contact Phone"
                  prop="phone"
                >
                  <el-input
                    v-model="forms.phone"
                    placeholder="Enter phone"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="Default receiving address"
                  prop="receivingAddress"
                >
                  <el-input
                    v-model="forms.receivingAddress"
                    placeholder="Enter receivingAddresss"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="CRO No."
                  prop="crono"
                >
                  <el-input
                    v-model="forms.crono"
                    placeholder="Enter CRO No."
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="EORI No (VAT NO)"
                  prop="eori no"
                >
                  <el-input
                    v-model="forms.eori"
                    placeholder="Enter EORI No."
                  ></el-input>
                </el-form-item>

              </el-form>
                <div class="container_main_right_form_body_subbtn">
                  Edit
                </div>
            </div>

          </div>

        </div>
        <div v-show="passActive">
           <div class="container_main_right_title">
            Password
          </div>
          <div class="container_main_right_form">
            <div class="container_main_right_form_passform">
              <el-form
                ref="forms"
                :model="forms"
                @submit="submitPasswordForm"
              >
              <el-form-item
                  label="Original Password"
                  prop="originpass"
                >
                  <el-input
                    v-model="forms.originpass"
                    placeholder="Enter Original Password"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="New Password"
                  prop="newpassword"
                >
                  <el-input
                    v-model="forms.newpassword"
                    placeholder="Enter New Password"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="Confirm New Password"
                  prop="renewpassword"
                >
                  <el-input
                    v-model="forms.renewpassword"
                    placeholder="Enter Confirm New Password"
                  ></el-input>
                </el-form-item>
              </el-form>

            </div>
            <div class="container_main_right_form_btn">
                <div class="container_main_right_form_btn_savebtn">save</div>
            </div>
            <div class="container_main_right_form_tip">
              Please log in again after changing the password
            </div>
          </div>
        </div>
        <div v-show="logActive">logout</div>

        <!-- 客户 - 用户信息 -->
        <CustomerInformation v-show="userType == 2 && infoActive" />

      </div>
    </div>

    <Foot />
  </div>
</template>

<script setup name="CustomerCenter">
import { ref } from 'vue'
import Foot from '../../components/Foot.vue'
// 用户信息
import CustomerInformation from '../../components/userCenter/CustomerInformation.vue'
import { useRoute, useRouter } from 'vue-router'
const router = useRouter()
const route = useRoute()
// 用户身份 1-供应商 2-客户
const userType = ref(2)
userType.value = route.query.userType
const infoActive = ref(true)
const passActive = ref(false)
const logActive = ref(false)
const changeTab = (index) => {
  switch (index) {
    case 1:

      infoActive.value = true
      passActive.value = false
      logActive.value = false

      break
    case 2:

      infoActive.value = false
      passActive.value = true
      logActive.value = false

      break
    case 3:

      infoActive.value = false
      passActive.value = false
      logActive.value = true

      break
  }
}
const forms = ref({
  companyName: '',
  companyAddress: '',
  switchboard: '',
  Contacts: '',
  receivingAddress: '',
  email: '',
  phone: '',
  crono: ''
})
</script>
<style lang="scss" scoped>
.container {
  width: 100%;

  background-color: #f8f8f8;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 50px;

  &_main {
    width: 63%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 200px;
    &_left {
      background: #ffffff;
      flex: 1;
      margin-right: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 30px 30px 30px 0;
      border-radius: 8px;
      &_title {
        margin-left: 30px;
        color: #181818;
        font-size: 20px;
        font-weight: 600;
      }
      &_tabtitle {
        color: #181818;
        font-size: 16px;
        padding: 20px 30px 20px 30px;
        margin-top: 30px;
        &--choosed {
          padding: 20px 0 20px 30px;
          margin-top: 30px;
          color: #27ae60;
          font-size: 16px;
          background-color: rgba(39, 174, 96, 0.04);
          width: 100%;
          text-align: left;
          overflow: hidden;
          border-left: 2px solid #27ae60;
        }
      }
    }
    &_right {
      padding: 30px 30px 30px 30px;
      flex: 3;
      background: #ffffff;
      text-align: left;
      &_title {
        color: #181818;
        font-size: 20px;
        font-weight: 600;
      }
      &_company {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 60px;
        background-color: rgba(248, 248, 248, 0.8);
        padding: 25px 30px;
        border-radius: 8px;
        &_left {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          &_img {
            img {
              width: 60px;
              height: 60px;
            }
          }
          &_title {
            font-size: 16px;
            color: #181818;
            margin-left: 20px;
          }
        }
        &_right {
          &_btn {
            background-color: rgba(39, 174, 96, 0.04);
            padding: 15px 30px;
            color: #27ae60;
            border: 1px solid #27ae60;
            font-size: 14px;
            border-radius: 6px;
          }
        }
      }

      &_form {
        margin-top: 50px;
        &_title {
          font-size: 16px;
          color: #181818;
        }
        &_body {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          &_subbtn{
            background-color: rgba(39, 174, 96, 0.04);
            padding: 15px 30px;
            color: #27ae60;
            border: 1px solid #27ae60;
            font-size: 14px;
            border-radius: 6px;
            width: 85px;
            text-align: center;
          }
          ::v-deep .el-form {
            width: 100%;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .el-form-item {
              margin-bottom: 30px;
              width: 45%;
              display: flex;
              flex-direction: column;

              .el-form-item__label {
                width: 45%;
                justify-content: flex-start;
                font-size: 14px;
                line-height: 1;
                height: 14px;
                margin-bottom: 10px;
                color: #181818;
                font-weight: 300;
              }

              .el-form-item__content {
                .el-input {
                  height: 46px;

                  .el-input__wrapper {
                    padding: 0px 20px;
                    box-shadow: none;
                    background-color: rgba(249, 249, 249, 1);

                    .el-input__inner {
                      color: rgba(24, 24, 24, 1);
                      font-size: 16px;

                      &::placeholder {
                        color: rgba(173, 173, 173, 0.6);
                      }
                    }
                  }

                  .is-focus {
                    box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, 0.1);
                    border: 1px solid rgba(39, 174, 96, 1);
                    box-sizing: border-box;
                  }
                }

                .el-select {
                  width: 45%;

                  .el-input__wrapper.is-focus {
                    box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, 0.1) !important;
                    border: 1px solid rgba(39, 174, 96, 1);
                    box-sizing: border-box;
                  }

                  .is-focus {
                    box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, 0.1);
                    border: 1px solid rgba(39, 174, 96, 1);
                    box-sizing: border-box;
                  }
                }
              }

              .el-button {
                border-radius: 4px;
                background-color: rgba(39, 174, 96, 1);
                box-shadow: none;
                border: none;
                font-size: 14px;
                line-height: 14px;
                font-weight: 700;
                padding: 27px 30px;
              }
            }
          }

          .el-form-line {
            width: 100%;
            margin: 20px auto 50px;
            height: 1px;
            border-bottom: 1px dashed rgba(39, 174, 96, 0.5);
          }

          .el-form-line2 {
            width: 100%;
            margin: 20px auto 50px;
            font-size: 14px;
            line-height: 1;
            color: rgba(36, 89, 2, 1);
            display: flex;
            align-items: center;
            justify-content: flex-start;

            &::after {
              content: "";
              display: inline-block;
              width: 100%;
              height: 1px;
              border-bottom: 1px dashed rgba(173, 173, 173, 0.6);
            }

            img {
              width: 14px;
              margin: 0 15px;
            }

            span {
              padding-right: 30px;
              white-space: nowrap;
            }
          }

        }

        &_passform{

          ::v-deep .el-form{
            width: 100%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
          align-items: center;
          justify-content: center;
            .el-form-item {
              margin-bottom: 30px;
              width: 60%;
              display: flex;
              flex-direction: column;

              .el-form-item__label {
                width: 60%;
                justify-content: flex-start;
                font-size: 14px;
                line-height: 1;
                height: 14px;
                margin-bottom: 10px;
                color: #181818;
                font-weight: 300;
              }

              .el-form-item__content {
                .el-input {
                  height: 46px;

                  .el-input__wrapper {
                    padding: 0px 20px;
                    box-shadow: none;
                    background-color: rgba(249, 249, 249, 1);

                    .el-input__inner {
                      color: rgba(24, 24, 24, 1);
                      font-size: 16px;

                      &::placeholder {
                        color: rgba(173, 173, 173, 0.6);
                      }
                    }
                  }

                  .is-focus {
                    box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, 0.1);
                    border: 1px solid rgba(39, 174, 96, 1);
                    box-sizing: border-box;
                  }
                }

                .el-select {
                  width: 45%;

                  .el-input__wrapper.is-focus {
                    box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, 0.1) !important;
                    border: 1px solid rgba(39, 174, 96, 1);
                    box-sizing: border-box;
                  }

                  .is-focus {
                    box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, 0.1);
                    border: 1px solid rgba(39, 174, 96, 1);
                    box-sizing: border-box;
                  }
                }
              }

              .el-button {
                border-radius: 4px;
                background-color: rgba(39, 174, 96, 1);
                box-shadow: none;
                border: none;
                font-size: 14px;
                line-height: 14px;
                font-weight: 700;
                padding: 27px 30px;
              }
            }
          }
        }
        &_btn{
          display: flex;
          justify-content: center;
          margin-top: 50px;
          &_savebtn{
            width: 92px;
            padding: 12px 20px 12px 20px;
            line-height: 20px;
            border-radius: 4px;
            background-color: rgba(39, 174, 96, 1);
            text-align: center;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
          }
        }
        &_tip{
          margin-top: 30px;
          display: flex;
          justify-content: center;
          color: #ADADAD;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
